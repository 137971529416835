import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockContent from './block-content'
import Container from './container'
import RoleList from './role-list'
import SEO from '../components/seo'
import BlockText from './block-text'
import ApplauseButton from './ApplauseButton'

import styles from './blog-post.module.css'

function BlogPost (props) {
  const { _rawBody, authors, categories, title, mainImage, publishedAt, slug } = props
  
  const url = `https://www.rockyoga.studio/blog/${slug.current}`
  
  return (
    <article className={styles.root}>
      <SEO title={title} />
      <Container>
        <div className="inner-container">
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{title}</h1>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
            {publishedAt && (
              <div className={styles.publishedAt}>
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? distanceInWords(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), 'MMMM Do YYYY')}
              </div>
            )}
            <ApplauseButton url={url} />
            </div>

        {mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(1200)
              .height(Math.floor((9 / 16) * 1200))
              .fit('crop')
              .url()}
            alt={mainImage.alt}
          />
        </div>
      )}
            {_rawBody && <BlockContent blocks={_rawBody} />}
            </div>
          </div>
        {/* {authors && <RoleList items={authors} title='Authors' />} */}
      </Container>
    </article>
  )
}

export default BlogPost
